import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts"; // Importando componentes da React Pie Chart
import db from "../config/firebase";

const ChartPage = () => {
  const [carregando, setCarregando] = useState(false);

  const [dadosEtnia, setDadosEtnia] = useState([]);
  const [dadosGenero, setDadosGenero] = useState([]);
  const [dadosIdade, setDadosIdade] = useState([]);
  const [dadosOrientacaoSexual, setDadosOrientacaoSexual] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await buscarEtnia();
      await buscarGenero();
      await buscarIdade();
      await buscarOrientacaoSexual();
      setCarregando(true);
    };
    fetchData();
  }, []);

  const buscarEtnia = async () => {
    const brancoCount = (
      await getDocs(
        query(collection(db, "usuarios"), where("etnia", "==", "branco"))
      )
    ).size;
    const pretoCount = (
      await getDocs(
        query(collection(db, "usuarios"), where("etnia", "==", "preto"))
      )
    ).size;
    const pardoCount = (
      await getDocs(
        query(collection(db, "usuarios"), where("etnia", "==", "pardo"))
      )
    ).size;
    const amareloCount = (
      await getDocs(
        query(collection(db, "usuarios"), where("etnia", "==", "amarelo"))
      )
    ).size;
    const indigenaCount = (
      await getDocs(
        query(collection(db, "usuarios"), where("etnia", "==", "indigena"))
      )
    ).size;

    setDadosEtnia([
      { name: "Branco", Pessoas: brancoCount, pv: 2400, amt: 2400 },
      { name: "Preto", Pessoas: pretoCount, pv: 2400, amt: 2400 },
      { name: "Pardo", Pessoas: pardoCount, pv: 2400, amt: 2400 },
      { name: "Amarelo", Pessoas: amareloCount, pv: 2400, amt: 2400 },
      { name: "Indígena", Pessoas: indigenaCount, pv: 2400, amt: 2400 },
    ]);
  };

  const buscarGenero = async () => {
    const masculinoCount = (
      await getDocs(
        query(collection(db, "usuarios"), where("genero", "==", "masculino"))
      )
    ).size;
    const femininoCount = (
      await getDocs(
        query(collection(db, "usuarios"), where("genero", "==", "feminino"))
      )
    ).size;
    const outroCount = (
      await getDocs(
        query(collection(db, "usuarios"), where("genero", "==", "outro"))
      )
    ).size;

    setDadosGenero([
      { name: "Masculino", Pessoas: masculinoCount, pv: 2400, amt: 2400 },
      { name: "Feminino", Pessoas: femininoCount, pv: 2400, amt: 2400 },
      { name: "Outros", Pessoas: outroCount, pv: 2400, amt: 2400 },
    ]);
  };

  const buscarIdade = async () => {
    const idade18_22Count = (
      await getDocs(
        query(collection(db, "usuarios"), where("idade", "==", "18-22"))
      )
    ).size;
    const idade23_30Count = (
      await getDocs(
        query(collection(db, "usuarios"), where("idade", "==", "23-30"))
      )
    ).size;
    const idade31_40Count = (
      await getDocs(
        query(collection(db, "usuarios"), where("idade", "==", "31-40"))
      )
    ).size;
    const idade41MaisCount = (
      await getDocs(
        query(collection(db, "usuarios"), where("idade", "==", "41+"))
      )
    ).size;

    setDadosIdade([
      { name: "18-22", Pessoas: idade18_22Count },
      { name: "23-30", Pessoas: idade23_30Count },
      { name: "31-40", Pessoas: idade31_40Count },
      { name: "41+", Pessoas: idade41MaisCount },
    ]);
  };

  const buscarOrientacaoSexual = async () => {
    const heterossexualCount = (
      await getDocs(
        query(
          collection(db, "usuarios"),
          where("orientacaoSexual", "==", "Heterossexual")
        )
      )
    ).size;
    const homossexualCount = (
      await getDocs(
        query(
          collection(db, "usuarios"),
          where("orientacaoSexual", "==", "Homossexual")
        )
      )
    ).size;
    const bissexualCount = (
      await getDocs(
        query(
          collection(db, "usuarios"),
          where("orientacaoSexual", "==", "Bissexual")
        )
      )
    ).size;
    const outrosCount = (
      await getDocs(
        query(
          collection(db, "usuarios"),
          where("orientacaoSexual", "==", "Outros")
        )
      )
    ).size;
    const preferirNaoResponderCount = (
      await getDocs(
        query(
          collection(db, "usuarios"),
          where("orientacaoSexual", "==", "Prefiro não responder")
        )
      )
    ).size;

    setDadosOrientacaoSexual([
      { name: "Heterossexual", Pessoas: heterossexualCount },
      { name: "Homossexual", Pessoas: homossexualCount },
      { name: "Bissexual", Pessoas: bissexualCount },
      { name: "Outros", Pessoas: outrosCount },
      { name: "Prefiro não responder", Pessoas: preferirNaoResponderCount },
    ]);
  };

  const generateRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "20%", marginBottom: "4%"}}
    >
      {carregando && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div style={{ flex: 1, marginRight: "20px" }}>
            <div style={{ marginBottom: "20px" }}>
              <h2>Gráfico de Etnia</h2>
              <PieChart width={400} height={400}>
                <Pie
                  data={dadosEtnia}
                  dataKey="Pessoas"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                >
                  {dadosEtnia.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={generateRandomColor()} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: "20px" }}>
            <div style={{ marginBottom: "20px" }}>
              <h2>Gráfico de Gênero</h2>
              <PieChart width={400} height={400}>
                <Pie
                  data={dadosGenero}
                  dataKey="Pessoas"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#82ca9d"
                >
                  {dadosGenero.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={generateRandomColor()} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div>
          </div>
        </div>
      )}
      {carregando && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div style={{ flex: 1, marginRight: "20px" }}>
            <div style={{ marginBottom: "20px" }}>
              <h2>Gráfico de Idade</h2>
              <PieChart width={400} height={400}>
                <Pie
                  data={dadosIdade}
                  dataKey="Pessoas"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#ffc658"
                >
                  {dadosIdade.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={generateRandomColor()} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: "20px" }}>
            <div style={{ marginBottom: "20px" }}>
              <h2>Gráfico de Orientação Sexual</h2>
              <PieChart width={400} height={400}>
                <Pie
                  data={dadosOrientacaoSexual}
                  dataKey="Pessoas"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#ff7300"
                >
                  {dadosOrientacaoSexual.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={generateRandomColor()} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartPage;
