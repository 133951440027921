import React from 'react';
import Gallery from '../components/Quiz/Gallery';
import Game from '../components/Quiz/Game';


const quiz = () => {
    return (
        <>
        <Gallery />
        <Game />
        </>
    );
}

export default quiz;