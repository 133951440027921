import React, { useState } from "react";
import "./style.css";
import { Typography, Rating } from "@mui/material";

import { collection, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import db from "../../config/firebase";

import { useEffect } from "react";
import { getAuth } from "firebase/auth";

const Cursos = () => {
  const [expanded, setExpanded] = useState(false);
  const [infoAulas, setInfoAulas] = useState();
  const [rating, setRating] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [trilhaList, setTrilhaList] = useState([]);
  const [trilhaId, setTrilhaId] = useState("");
  const auth = getAuth();

  const clickLink = (link, trilhaID, aulaID) => {
    if (trilhaID && aulaID && auth.currentUser) {
      const docAssistiu = doc(
        db,
        "trilhas",
        trilhaID,
        "usuarios",
        auth.currentUser.uid
      );
      let dados = {};
      dados[aulaID] = true;
      setDoc(docAssistiu, dados, { merge: true });
    }

    window.open(link);
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    with: "100%",
    gap: "15px",
    alignItems: "center",
    marginTop: "50px",
    marginBottom: "50px",
  };

  const detailsBox = {
    width: "70%",
  };

  const boxStyle = {
    width: "80%",
    height: "50%",
    backgroundColor: "White",
    border: "1px solid #000",
    padding: "5px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
  };

  const buttonStyle = {
    padding: "10px",
    backgroundColor: "blue",
    color: "white",
    cursor: "pointer",
    borderRadius: "10px",
    marginRight: "10px",
    fontSize: "16px",
    fontWeight: "bold",
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  // ======================================================================================================

  useEffect(() => {
    getFirestoreData();
  }, []);

  const getFirestoreData = async () => {
    console.log("AAAAA");
    try {
      const querySnapshot = await getDocs(collection(db, "trilhas"));

      const retorno = querySnapshot.docs.map(async (item) => {
        const dados = { ...item.data(), id: item.id };

        if (auth.currentUser) {
          const docAssistiu = doc(
            db,
            "trilhas",
            item.id,
            "usuarios",
            auth.currentUser.uid
          );
          const docAssistiuSnapshot = await getDoc(docAssistiu);
          dados["aulas_assistidas"] = docAssistiuSnapshot.data();
        }
        console.log(dados);
        return dados;
      });

      Promise.all(retorno).then((values) => {
        console.log(values);

        setTrilhaList(values);
      });
    } catch (erro) {
      alert(erro);
    }
  };

  const assistiuAula = (trilha, numeroAula) => {
    if (auth.currentUser && trilha.aulas_assistidas) {
      return trilha.aulas_assistidas[numeroAula] === true;
    }
  };

  // ======================================================================================================

  return (
    <div style={containerStyle}>
      {trilhaList.map((trilha) => (
        <>
          {trilha.show && ( // Verifica se trilha.show é true
            <>
              <h1>{trilha.nome}</h1>
              {trilha.aulas &&
                Object.values(trilha.aulas).map((aula) => {
                  return (
                    <div className="hover-box" style={boxStyle}>
                      {/* <h1>{trilha}</h1> */}
                      <img
                        style={{ width: "18%" }}
                        src={aula.imgAula}
                        alt={aula.imgAula}
                      />
                      <div style={detailsBox}>
                        <h1>{aula.titulo}</h1>
                        <p>{aula.resumoAula}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                        }}
                      >
                        <button
                          style={Object.assign(
                            {},
                            buttonStyle,
                            assistiuAula(trilha, aula.nmrAula)
                              ? { backgroundColor: "lightgray" }
                              : {}
                          )}
                          onClick={() =>
                            clickLink(aula.videoUrl, trilha.id, aula.nmrAula)
                          }
                        >
                          {assistiuAula(trilha, aula.nmrAula)
                            ? "Assistiu"
                            : "Assistir"}
                        </button>
                        <button
                          style={buttonStyle}
                          onClick={() => clickLink(aula.linkpdf)}
                        >
                          Pdf
                        </button>
                        <Typography variant="h6" sx={{ fontSize: 16 }}>
                          Avalie a aula
                        </Typography>
                        <Rating
                          name="rating"
                          value={rating}
                          onChange={handleRatingChange}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </>
      ))}
    </div>
  );
};

export default Cursos;
